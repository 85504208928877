import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestAudioPermission, getApiUrl } from '../utils/commonFunctions';

const fetchCategories = async () => {
    const url = getApiUrl('/api/categories');
    //console.log(`fetchCategories url: ${url}`);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`카테고리를 불러오는데 실패했습니다: ${error}`);
    }
};

const fetchCreateChatRoom = async (roomInfo) => {
    const url = getApiUrl('/api/chatRooms');
    //console.log(`fetchCreateChatRoom url: ${url}`);
    try {
      const response = await fetch(url, {
        method: 'POST', // HTTP 메소드를 POST로 변경
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(roomInfo) // roomInfo를 JSON 문자열로 변환하여 요청 본문에 포함
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('채팅방을 생성하는데 실패했습니다:', error);
    }
  };

const CreateChatModal = ({ setIsModalOpen }) => {
  const [categories, setCategories] = useState([]);
  const [roomInfo, setRoomInfo] = useState({
    categoryId: '',
    title: '',
    totalMembers: '2',
    name: ''
  });
  const navigate = useNavigate();
  const titleInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoomInfo({
      ...roomInfo,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { title, totalMembers, name } = roomInfo;

    if (!title || title.trim() === '' ||
        !totalMembers || totalMembers.trim() === '' ||
        !name || name.trim() === '') 
    {
      alert('*은 필수 입력값입니다.');
      return;
    }

    const permissionGranted = await requestAudioPermission();
    if (!permissionGranted) return;

    try {
      const createdRoom = await fetchCreateChatRoom(roomInfo);
      console.log('Room Info:', roomInfo);
      setIsModalOpen(false);
      navigate(`/chatroom`, { state: { roomId: createdRoom.roomId, name: name, auth: 'manager', title: title } });
    } catch (error) {
      console.error('채팅방 생성 중 오류 발생:', error);
    }
  };

  useEffect(() => {
    const init = async () => {
      const data = await fetchCategories();
      setCategories(data);
      if (data.length > 0) {
        setRoomInfo(prev => ({
          ...prev,
          categoryId: data[1].categoryId
        }));
      }
    };

    init();

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setIsModalOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [setIsModalOpen]); // setIsModalOpen을 의존성 배열에 추가

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
        <form className="create-chat-room" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>카테고리 *</label>
            <select name="categoryId" value={roomInfo.categoryId} onChange={handleInputChange} className="form-control">
              {categories.filter(category => category.categoryName !== 'ALL').map((category) => (
                <option key={category.categoryId} value={category.categoryId}>{category.categoryName}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>제목 *</label>
            <input 
              type="text" 
              name="title" 
              value={roomInfo.title} 
              onChange={handleInputChange}
              maxLength="100"
              ref={titleInputRef}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>인원수 *</label>
            <input 
              type="number" 
              name="totalMembers" 
              value={roomInfo.totalMembers} 
              onChange={handleInputChange} 
              min="2" 
              max="50" 
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>닉네임 *</label>
            <input 
              type="text" 
              name="name" 
              value={roomInfo.name} 
              onChange={handleInputChange}
              maxLength="12"
              className="form-control"
            />
          </div>
          <button type="submit" className="submit-button">만들기</button>
        </form>
      </div>
    </div>
  )
}

export default CreateChatModal;